<template>
	<div class="grid">
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Ventas, últimos 7 días</span>
						<div class="text-900 font-medium text-xl">{{ this.last7daysSalesAmount }}</div>
					</div>
					<div class="flex align-items-center justify-content-center border-round" style="width:2.5rem;height:2.5rem; background: #f18700;">
						<img src="layout/images/iconos-landingpage/1-19.png" class="bottom-0" alt="icon-1" style="width: 3rem;">
					</div>
				</div>
				<span :class="{'text-green-500': (this.last30daysSalesAmount>1), 'font-medium': true}">{{ this.last30daysSalesAmount }} {{" " + ((this.last30daysSalesAmount == 1) ? " venta" : "ventas" ) }}</span>
				<span class="text-500">en los últimos 30 días</span>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Ingresos, últimos 7 días</span>
						<div class="text-900 font-medium text-xl">${{ this.last7daysSalesTotal.toFixed(2) }}</div>
					</div>
					<div class="flex align-items-center justify-content-center border-round" style="width:2.5rem;height:2.5rem; background: #6ebb71;">
						<!-- <i class="pi pi-map-marker text-xl" style="color: white;"></i> -->
						<img src="layout/images/iconos-landingpage/1-20.png" class="bottom-0" alt="icon-1" style="width: 3rem;">
					</div>
				</div>
				<span :class="{'text-green-500': (this.last30daysSalesTotal>0), 'font-medium': true}">${{ this.last30daysSalesTotal.toFixed(2) }}</span>
				<span class="text-500">en los últimos 30 días</span>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Gastos, últimos 7 días</span>
						<div class="text-900 font-medium text-xl">${{ this.last7DaysExpensesTotal.toFixed(2) }}</div>
					</div>
					<div class="flex align-items-center justify-content-center border-round" style="width:2.5rem;height:2.5rem; background: #dd4444;">
						<!-- <i class="pi pi-inbox text-xl" style="color: white;"></i> -->
						<img src="layout/images/iconos-landingpage/1-21.png" class="bottom-0" alt="icon-1" style="width: 3rem;">
					</div>
				</div>
				<span :class="{'text-blue-500': (this.last30DaysExpensesTotal>0), 'font-medium':true}">${{ this.last30DaysExpensesTotal.toFixed(2) }}</span>
				<span class="text-500">en los últimos 30 días</span>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Clientes</span>
						<div class="text-900 font-medium text-xl">{{ this.customerAmount }}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-purple-100 border-round" style="width:2.5rem;height:2.5rem">
						<!-- <i class="pi pi-comment text-purple-500 text-xl"></i> -->
						<img src="layout/images/iconos-landingpage/1-22.png" class="bottom-0" alt="icon-1" style="width: 3rem;">
					</div>
				</div>
				<span :class="{'text-blue-500': (this.last7DaysCustomers > 0), 'font-medium':true}"> {{ this.last7DaysCustomers }} {{" " + ((this.last7DaysCustomers == 1) ? " cliente" : "clientes" ) }}</span>
				<span class="text-500">En los últimos 7 días</span>
			</div>
		</div>

	<div class="col-12 xl:col-6">
		<div class="card">
			<h5>Ventas Recientes</h5>
			<DataTable :value="this.recentSales" :rows="5" :paginator="true" responsiveLayout="scroll">
				<Column style="width:25%">
						<template #header>
							Fecha
						</template>
						<template #body="slotProps">
							{{ slotProps.data.createdAtFormatted }}
						</template>
					</Column>
				<Column style="width:35%">
					<template #header>
						Cliente
					</template>
					<template #body="slotProps">
						{{ slotProps.data.customer.name }}
					</template>
				</Column>
				<Column style="width:15%">
					<template #header>
						Total
					</template>
					<template #body="slotProps">
						${{ slotProps.data.total.toFixed(2) }}
					</template>
				</Column>
				<Column style="width:25%">
					<template #header>
						Actividad
					</template>
					<template #body="slotProps">
						{{ slotProps.data.econActivity.name }}
					</template>
				</Column>
			</DataTable>
		</div>
		<!-- <div class="card">
			<div class="flex justify-content-between align-items-center mb-5">
				<h5>Best Selling Products</h5>
				<div>
					<Button icon="pi pi-ellipsis-v" class="p-button-text p-button-plain p-button-rounded" @click="$refs.menu2.toggle($event)"></Button>
					<Menu ref="menu2" :popup="true" :model="items"></Menu>
				</div>
			</div>
			<ul class="list-none p-0 m-0">
				<li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
					<div>
						<span class="text-900 font-medium mr-2 mb-1 md:mb-0">Space T-Shirt</span>
						<div class="mt-1 text-600">Clothing</div>
					</div>
					<div class="mt-2 md:mt-0 flex align-items-center">
						<div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
							<div class="bg-orange-500 h-full" style="width:50%"></div>
						</div>
						<span class="text-orange-500 ml-3 font-medium">%50</span>
					</div>
				</li>
				<li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
					<div>
						<span class="text-900 font-medium mr-2 mb-1 md:mb-0">Portal Sticker</span>
						<div class="mt-1 text-600">Accessories</div>
					</div>
					<div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
						<div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
							<div class="bg-cyan-500 h-full" style="width:16%"></div>
						</div>
						<span class="text-cyan-500 ml-3 font-medium">%16</span>
					</div>
				</li>
				<li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
					<div>
						<span class="text-900 font-medium mr-2 mb-1 md:mb-0">Supernova Sticker</span>
						<div class="mt-1 text-600">Accessories</div>
					</div>
					<div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
						<div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
							<div class="bg-pink-500 h-full" style="width:67%"></div>
						</div>
						<span class="text-pink-500 ml-3 font-medium">%67</span>
					</div>
				</li>
				<li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
					<div>
						<span class="text-900 font-medium mr-2 mb-1 md:mb-0">Wonders Notebook</span>
						<div class="mt-1 text-600">Office</div>
					</div>
					<div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
						<div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
							<div class="bg-green-500 h-full" style="width:35%"></div>
						</div>
						<span class="text-green-500 ml-3 font-medium">%35</span>
					</div>
				</li>
				<li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
					<div>
						<span class="text-900 font-medium mr-2 mb-1 md:mb-0">Mat Black Case</span>
						<div class="mt-1 text-600">Accessories</div>
					</div>
					<div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
						<div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
							<div class="bg-purple-500 h-full" style="width:75%"></div>
						</div>
						<span class="text-purple-500 ml-3 font-medium">%75</span>
					</div>
				</li>
				<li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
					<div>
						<span class="text-900 font-medium mr-2 mb-1 md:mb-0">Robots T-Shirt</span>
						<div class="mt-1 text-600">Clothing</div>
					</div>
					<div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
						<div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
							<div class="bg-teal-500 h-full" style="width:40%"></div>
						</div>
						<span class="text-teal-500 ml-3 font-medium">%40</span>
					</div>
				</li>
			</ul>
		</div> -->
	</div>
	<div class="col-12 xl:col-6">
		<div class="card">
			<h5>Resumen de Movimientos</h5>
			<Dropdown id="finantialReportTimeDropdown" v-model="finantialReportTimeSelected" :options="finatialReportTimeOptions" optionLabel="name" @change="onFinantialReportTimeChanged()"></Dropdown>
			<Chart type="line" :data="lineData" :options="lineOptions" />
		</div>
		<!-- <div class="card">
			<div class="flex align-items-center justify-content-between mb-4">
				<h5>Notifications</h5>
				<div>
					<Button icon="pi pi-ellipsis-v" class="p-button-text p-button-plain p-button-rounded" @click="$refs.menu1.toggle($event)"></Button>
					<Menu ref="menu1" :popup="true" :model="items"></Menu>
				</div>
			</div>
			
			<span class="block text-600 font-medium mb-3">TODAY</span>
			<ul class="p-0 mx-0 mt-0 mb-4 list-none">
				<li class="flex align-items-center py-2 border-bottom-1 surface-border">
					<div class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
						<i class="pi pi-dollar text-xl text-blue-500"></i>
					</div>
					<span class="text-900 line-height-3">Richard Jones
						<span class="text-700">has purchased a blue t-shirt for <span class="text-blue-500">79$</span></span>
					</span>
				</li>
				<li class="flex align-items-center py-2">
					<div class="w-3rem h-3rem flex align-items-center justify-content-center bg-orange-100 border-circle mr-3 flex-shrink-0">
						<i class="pi pi-download text-xl text-orange-500"></i>
					</div>
					<span class="text-700 line-height-3">Your request for withdrawal of <span class="text-blue-500 font-medium">2500$</span> has been initiated.</span>
				</li>
			</ul>

			<span class="block text-600 font-medium mb-3">YESTERDAY</span>
			<ul class="p-0 m-0 list-none">
				<li class="flex align-items-center py-2 border-bottom-1 surface-border">
					<div class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
						<i class="pi pi-dollar text-xl text-blue-500"></i>
					</div>
					<span class="text-900 line-height-3">Keyser Wick
						<span class="text-700">has purchased a black jacket for <span class="text-blue-500">59$</span></span>
					</span>
				</li>
				<li class="flex align-items-center py-2 border-bottom-1 surface-border">
					<div class="w-3rem h-3rem flex align-items-center justify-content-center bg-pink-100 border-circle mr-3 flex-shrink-0">
						<i class="pi pi-question text-xl text-pink-500"></i>
					</div>
					<span class="text-900 line-height-3">Jane Davis
						<span class="text-700">has posted a new questions about your product.</span>
					</span>
				</li>
			</ul>
		</div> -->
	</div>
	</div>
</template>

<script>
import EventBus from '@/AppEventBus';
import httpRequest from '../service/httpRequest';	
import Sale from '../models/sale';

export default {
	data() {
		return {
			last7daysSalesAmount: 0,
			last30daysSalesAmount: 0,
			last7daysSalesTotal: 0.0,
			last30daysSalesTotal: 0.0,
			last7DaysExpensesTotal: 0.0,
			last30DaysExpensesTotal: 0.0,
			customerAmount: 0,
			last7DaysCustomers: 0,
			recentSales: [],
			lineData: {
				labels: [],
				datasets: [
					{
						label: 'Ingresos',
						data: [],
						fill: false,
						backgroundColor: '#06c35d',
						borderColor: '#06c35d',
						tension: 0.4
					},
					{
						label: 'Egresos',
						data: [],
						fill: false,
						backgroundColor: '#d45656',
						borderColor: '#d45656',
						tension: 0.4
					}
				]
			},
			finatialReportTimeOptions: [
				{name: 'Últimos 7 días', code: '1'},
				{name: 'Últimos 15 días', code: '2'},
				{name: 'Últimos 30 días', code: '4'},
				{name: 'Últimos 60 días', code: '8'},
				{name: 'Últimos 90 días', code: '12'},
			],
			finantialReportTimeSelected: {name: 'Últimos 7 días', code: '1'},
			lineOptions: null,
		}
	},
	themeChangeListener: null,
	mounted() {
		this.themeChangeListener = (event) => {
            if (event.dark)
                this.applyDarkTheme();
            else
                this.applyLightTheme();
        };
        EventBus.on('change-theme', this.themeChangeListener);

		if (this.isDarkTheme()) {
            this.applyDarkTheme();
        }
        else {
            this.applyLightTheme();
        }
	},
	beforeUnmount() {
        EventBus.off('change-theme', this.themeChangeListener );
    },
	async created() {
		this.getBusinessMetrics();
		this.getFinantialStateData();
		this.getRecentSales();
	},
	methods: {
		async getBusinessMetrics() {
			let response = await httpRequest.getBusinessMetrics();
			if (response.status == 200) {
				this.last7daysSalesAmount = response.data.last7daysSalesAmount;
				this.last30daysSalesAmount = response.data.last30daysSalesAmount;
				this.last7daysSalesTotal = response.data.last7daysSalesTotal;
				this.last30daysSalesTotal = response.data.last30daysSalesTotal;
				this.last7DaysExpensesTotal = response.data.last7DaysExpensesTotal;
				this.last30DaysExpensesTotal = response.data.last30DaysExpensesTotal;
				this.customerAmount = response.data.customerAmount;
				this.last7DaysCustomers = response.data.last7DaysCustomers;
			} else {
				console.log(response);
			}
		},
		async getFinantialStateData() {
			let response = await httpRequest.getFinantialStateData(this.finantialReportTimeSelected.code);
			if (response.status == 200) {
				// Get data
				this.lineData.datasets[0].data = response.data.sales;
				this.lineData.datasets[1].data = response.data.expenses;

				// Setup day labels
				for (var i=0; i<7; i++) {
					let mult = parseInt(this.finantialReportTimeSelected.code);
					var date = new Date();
					date.setDate(date.getDate() - (i * mult));
					this.lineData.labels.unshift(date.toLocaleDateString("es-ES"));
				}	
			} else {
				console.log(response);
			}
		},
		async onFinantialReportTimeChanged() {
			// Clear data
			this.lineData.labels = [];
			this.lineData.datasets[0].data = [];
			this.lineData.datasets[1].data = [];

			await this.getFinantialStateData();
		},
		async getRecentSales() {
			let response = await httpRequest.getRecentSales();
			if (response.status == 200) {
				let sales = response.data;
                this.recentSales = sales.map(function(data) { return new Sale(data)});
			} else {
				console.log(response.error);
			}
		},
		isDarkTheme() {
            return this.$appState.darkTheme === true;
        },
		applyLightTheme() {
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#495057'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
					y: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
				}
			};
		},
		applyDarkTheme() {
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#ebedef'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color:  'rgba(160, 167, 181, .3)',
						}
					},
					y: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color:  'rgba(160, 167, 181, .3)',
						}
					},
				}
			};
		}
	}
}
</script>

<style scoped lang="scss">
	#finantialReportTimeDropdown {
		position: absolute;
		top: 15px;
		right: 15px;
	}
</style>